.ws-dealer-reviews {
	// fixes the carousel when this widget is placed inside an
	// element set to display flex
	.slider > .slider-frame > .slider-list {
		width: auto !important;
	}

	.slider-container {
		padding: 0 48px;
	}
}

.slider-control-bottomcenter .paging-item button {
	// standardize IOS default padding for button elements with other browsers
	// TODO: replace the pagination dots with a design component
	padding: 0 7px;
}
